@import "styles/common.scss";

.button {
  &.unchanged {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  &:not(.unchanged) {
    display: flex;
    padding: 13.5px 16px 14.5px 20px;
    justify-content: flex-end;
    align-items: center;
    border: none;
    cursor: pointer;

    font-variant-numeric: lining-nums proportional-nums;

    @include Montserrat(14px, var(--Hifi-Main-White, #FFF), 700, 100%);

    white-space: nowrap;

    @include phone() {
      padding: 9.5px 12px 10.5px 12px;
    }

    &:disabled {
      opacity: 0.4;

      &:hover {
        cursor: not-allowed !important;
      }
    }

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &.filled {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    background: var(--Hifi-Shades-09, #023055);

    /* Button Shadow */
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.15);

    &.loading {
      position: relative;

      .loader {
        position: absolute;
      }

      .icon_left {
        visibility: hidden;
      }

      .icon_right {
        visibility: hidden;
      }

      .children {
        visibility: hidden;
      }
    }
  }

  &.text-with-underline {
    background: none;
    text-decoration-line: underline;
    padding: 0;
    gap: 10px;
    @include Montserrat(18px, var(--Hifi-Main-Blue, #053B66), 500, 150%);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'clig' off, 'liga' off;
  }
}