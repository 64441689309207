// color variables
:root {
  --Hifi-Main-Blue: #053B66;
  --Hifi-Shades-09: #023055;
  --Hifi-Main-White: #ffffff;
  --Hifi-Shades-01: #FAFAF9;
  --Hifi-Shades-06: #E0D9CD;
  --Hifi-Shades-07: #ACA392;
  --Hifi-Main-NS-Granite-Gray: #676767;
  --Hifi-Main-Dark-Gray: #2B2B2B;
  --Secondary-Teal: #099;
  --error-color: #ff2b2b;
}