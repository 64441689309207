.global-alerts-wrapper {
  &.hidden {
    display: none;
  }

  z-index: 1000;

  position: fixed;
  top: -30px;
  width: 100%;

  padding: 50px;

  .global-alert {
    margin: auto;
    width: 100%;
    margin-bottom: 10px;
  }
}